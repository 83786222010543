<template>
  <section id="quendrox-services">
    <img
      src="@/assets/images/quendrox-management.jpg"
      alt="Quendrox HA"
      class="center"
    />
    <div class="readFriendlyLayout">
      <h2>Quendrox Services</h2>
      <p>
        We offer a broad varity of services such as;
      </p>
      <div class="services">
        <div v-for="service in services" v-bind:key="service" class="service">
          <p>{{ service }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "QuendroxServices",
  data() {
    return {
      services: [
        "Project management",
        "Test management",
        "Certification management",
        "Line management"
      ]
    };
  }
};
</script>

<style scoped lang="scss">
img {
  border-radius: 10px;
}

.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service {
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  justify-content: center;

  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  background-color: var(--light-accent);
  color: var(--light-shades);
}
</style>
